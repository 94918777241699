import React, { useState } from 'react';
import { CloseIcon, Container, ContainerButton, Conteudo, ModalFrame, Texto } from './styles';


function Modal({ isOpen, onClose, copyValue }) {
  // Se a modal não estiver aberta, não renderizar nada
  if (!isOpen) {
    return null;
  }

  // Função para copiar o valor para a área de transferência
  const handleCopy = () => {
    navigator.clipboard.writeText(copyValue);
  };

  return (
    <Container>
        <ModalFrame>
            <ContainerButton>
              <CloseIcon onClick={onClose} icon="iconamoon:close-bold" />
            </ContainerButton>
            {copyValue !== undefined && <Texto>Copie o link para o telegram:</Texto>}
            {
              copyValue === undefined  ? 
                <Texto>Codigo não encontrado</Texto>
              :
                <Conteudo>
                  <input type="text" value={copyValue} readOnly />
                  <button onClick={e => {e.stopPropagation(); handleCopy()}}>Copiar</button>
                </Conteudo>
            }
        </ModalFrame>
    </Container>
  );
}

export default Modal;
