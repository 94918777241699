import styled from 'styled-components';
import { Icon } from '@iconify/react';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 770px){
        width: 90vw;
        align-self: center;
    }
`

export const Conteudo = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    button {
        display: flex;
        width: 100%;
        height: 52px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: rgb(255, 255, 255);
        background: linear-gradient(rgb(1, 112, 1) 0%, rgb(0, 96, 1) 100%);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 14px;
        border-radius: 26px;
        border: none;
        margin: 12px 0px;
        cursor: pointer;
        text-transform: uppercase;
        padding: 8px 16px;
    }
    input {
        min-width: 550px;
        display: flex;
        width: 100%;
        height: 52px;
        background: #FFFFFF;
        border: 1px solid rgb(1, 21, 0);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 14px;
        border-radius: 26px;
        box-sizing: border-box;
        color: #000000;
        padding-left: 20px;
    }
`;

export const Texto = styled.div`
    font-family: Montserrat;
    font-style: normal;
    margin: 0 auto;
    font-size: 20px;
    line-height: 150%;
    color: rgb(255, 255, 255);
    margin-top: 0px;
`

export const CloseIcon = styled(Icon)`
    position: absolute;
    top: -26px;
    right: -26px;
    color: #FFF;
    font-size: 34px;
    cursor: pointer;
`;

export const ContainerButton = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const ModalFrame = styled.div`
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    padding: 40px;
`