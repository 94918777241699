import styled from 'styled-components'

export const Container = styled.div`
    background-image: url(https://alavancagem.ortegatips.com.br/static/media/fundoverdebg.d1b0adbeb593ff71ec6e.png);
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-size: cover;
    background-color: black;
    width: 100vw;
    height: 100vh;
    gap: 20px;

    @media screen and (max-width: 770px){
        max-width: 100vw;
        overflow-x: hidden;
        
    }
`

export const LogoContainer = styled.img`
    margin-top: 117px;
    width: 188.73px;
    height: 188.73px;

    @media screen and (max-width: 770px){
        margin-top: 20px; 
    }
`

export const TituloPagina = styled.h1`
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    margin-top: 0px;
    padding: 0px 30px;

    @media screen and (max-width: 770px){
        font-size: 28px;
        padding: 0;
        text-align: center;
    }

`;

export const Texto = styled.p`
    font-family: Montserrat;
    font-style: normal;
    margin: 0 auto;
    font-size: 20px;
    line-height: 150%;
    color: rgb(255, 255, 255);
    margin-top: 0px;
    padding: 0px 32px;
`
export const ContainerTextos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    @media screen and (max-width: 770px){
        max-width: 90vw;
    }
 
`;

export const ContainerForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    @media screen and (max-width: 770px){
        max-width: 80vw;
        align-items: center;
        justify-content: center;
 
    }
`;

export const ContainerInputs = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 770px){
        flex-direction: column;
    }
`;

export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    label {
        display: flex;
        font-family: Montserrat;
        font-style: italic;
        font-weight: 500;
   
        line-height: 150%;
        color: rgb(255, 255, 255);
        font-size: 16px;
    }
    input {
        display: flex;
        width: 100%;
        height: 52px;
        background: #FFFFFF;
        border: 1px solid rgb(1, 21, 0);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 14px;
        border-radius: 26px;
        box-sizing: border-box;
        color: #000000;
        padding-left: 20px;
    }

    input:focus{
        transform: none !important;
    }

    @media screen and (max-width: 770px){
        input{
            width: 300px;
            margin: 0;
        }
        label{
            width: 100%;
        }
    }

`;

export const Button = styled.button`
    display: flex;
    width: 100%;
    height: 52px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: rgb(255, 255, 255);
    background: linear-gradient(rgb(1, 112, 1) 0%, rgb(0, 96, 1) 100%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 14px;
    border-radius: 26px;
    border: none;
    margin: 12px 0px;
    cursor: pointer;
    text-transform: uppercase;

    @media screen and (max-width: 770px){
        max-width: 100vw;
        justify-content: center;
        align-items: center;
        width: 300px;
        margin: 0;
    }
`

export const Conteudo = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    button {
        display: flex;
        width: 100%;
        height: 52px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: rgb(255, 255, 255);
        background: linear-gradient(rgb(1, 112, 1) 0%, rgb(0, 96, 1) 100%);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 14px;
        border-radius: 8px;
        padding: 8px;
        border: none;
        margin: 12px 0px;
        cursor: pointer;
        text-transform: uppercase;
    }
    input {
        min-width: 700px;
        display: flex;
        width: 100%;
        height: 52px;
        background: #FFFFFF;
        border: 1px solid rgb(1, 21, 0);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 14px;
        border-radius: 26px;
        box-sizing: border-box;
        color: #000000;
        padding-left: 20px;
    }
`;

export const ErrorMensagem = styled.p`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
    color: rgb(255, 255, 255);
`;