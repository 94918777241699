import logo from './logo.svg';
import './App.css';
import { Container, ContainerInput, LogoContainer, Texto, TituloPagina, ContainerForm, ContainerInputs, Button, ContainerTextos, ErrorMensagem } from './styles';
import { useForm } from "react-hook-form"
import { useEffect, useState } from 'react';
import Modal from './components/modal';
import axios from 'axios';

function App() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const [modalOpen, setModalOpen] = useState(false);
  const [linkTelegram, setLinkTelegram] = useState('');
  const [exibirErro, setExibirErro] = useState(false);
  const [isDesktop, setIsDesktop] = useState();

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 769);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

  }, [])

  const onSubmit = async (data) => {
    setLinkTelegram('');
    console.log(data)
    
    await axios.get(`https://s2.linerapay.com.br/api/LineraPay/GetLinksBot?email=${data.email}&HotmartCodigoInternoCompra=${data.idCompra}`)
    .then(r => {
      console.log(r.data)
      setLinkTelegram(r.data.grupos[0]);
      if(r.data.grupos[0] != null || r.data.grupos[0] != undefined || r.data.grupos[0] != "" || r.data.grupos[0] && !exibirErro){
        if(r.data.grupos[0] == undefined){
          setModalOpen(true)
        } else {
          document.location.href = r.data.grupos[0];
        }
        
        
      } else {
        
        setModalOpen(true)
        
      }
     
    })
  }

  useEffect(() => {
    console.log(linkTelegram)
  }, [linkTelegram])

  return (
    <>
      <Container>
        <LogoContainer src="https://alavancagem.ortegatips.com.br/static/media/logo.d91e6db344d81d2a89d1.png" />
        <ContainerTextos>
          <TituloPagina>Adquira o link de convite de nossos grupos</TituloPagina>
          <Texto>
            Coloque suas informações abaixo para receber os links para nosso grupo.
          </Texto>
        </ContainerTextos>
        <ContainerForm onSubmit={handleSubmit(onSubmit)}>
          <ContainerInputs>
            <ContainerInput style={{width: `${isDesktop && '60%'}`}}>
              <label for="email">* Email:</label>
              <input placeholder='example@email.com' {...register("email", {
                required: "campo requerido",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entre com um email valido"
                }
              })} />
            </ContainerInput>
            <ContainerInput>
              <label for="idCompra">* Codigo da Compra:</label>
              <input {...register("idCompra", { required: true })} />
            </ContainerInput>
          </ContainerInputs>

          {exibirErro && <ErrorMensagem>Preencha todos os campos corretamente.</ErrorMensagem>}
          <Button type="submit" onClick={() => {
            if(errors.email || errors.idCompra) {
              setExibirErro(true);
            } else {
              setExibirErro(false);
            }
          }}>CONSULTAR</Button>
        </ContainerForm>
      </Container>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        copyValue={linkTelegram}
      />
    </>
  );
}

export default App;
